import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './utils/vant'
import { Menu,MenuItem,Submenu,Icon } from 'element-ui';
import './assets/css/index.scss'
import "lib-flexible/flexible"
Vue.config.productionTip = false

Vue.component(Menu.name, Menu);
Vue.component(MenuItem.name, MenuItem);
Vue.component(Submenu.name, Submenu);
Vue.component(Icon.name,Icon)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
