import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path:'/courseGuid',
    name:'courseGuid',
    component: () => import('../views/courseGuid/courseGuid.vue')
  },

  {
    path: '/',
    name: 'home',
    component: () => import('../views/home/home.vue'),
    children:[
      {
        path:'',
        name:'HelpHome',
        component: () => import('../views/helpInfo/helpHome.vue')
      },
      {
        path:'mattercollect',
        name:'MatterCollect',
        component: () => import('../views/helpInfo/matterCollect.vue'),
      },
      {
        path:'newhandarea',
        name:'newHandArea',
        component: () => import('../views/helpInfo/newHandArea.vue')
      },
      {
        path:'details',
        name:'Details',
        component: () => import('../views/helpInfo/details.vue')
      },
      {
        path:'search',
        name:'Search',
        component: () => import('../views/search/search.vue')
      },
    ]
  }
]

const router = new VueRouter({
  routes
})

export default router
