import Vue from 'vue'

import {
  Button,
  Popup,
  Toast,
  Search,
  Tab, 
  Tabs,
  Icon,
  Collapse, 
  CollapseItem,

/*   Image as VanImage,
  ActionSheet,
  Tag,
  Calendar,
  Checkbox, 
  CheckboxGroup,
  DatetimePicker,
  Field, 
  Form,  
  NumberKeyboard, 
  PasswordInput,
  Picker,
  RadioGroup, 
  Radio,
  Switch, 
  Uploader,
  Dialog,
  DropdownMenu, 
  DropdownItem,
  Loading,
  Notify,
  Overlay,
  PullRefresh,
  Empty,
  ImagePreview,
  Lazyload,
  List,
  Popover,
  Progress,
  Swipe, 
  SwipeItem,
  Sticky,
  Grid, 
  GridItem, */

  
  
} from 'vant'

Vue.use(Button)
.use(Popup)
.use(Tab)
.use(Tabs)
.use(Icon)
.use(Search)
.use(Collapse)
.use(CollapseItem)

/* .use(Calendar)
.use(Checkbox)
.use(CheckboxGroup)
.use(DatetimePicker)
.use(Field)
.use(Form)
.use(NumberKeyboard)
.use(PasswordInput)
.use(Picker)
.use(Radio)
.use(RadioGroup)
.use(Switch)
.use(Uploader)
.use(Dialog)
.use(DropdownMenu)
.use(DropdownItem)
.use(Loading)
.use(Notify)
.use(Overlay)
.use(PullRefresh)
.use(Empty)
.use(ImagePreview)
.use(Lazyload)
.use(List)
.use(Popover)
.use(Progress)
.use(Swipe)
.use(SwipeItem)
.use(Sticky)
.use(Grid)
.use(GridItem)
.use(ActionSheet)
.use(Tag) */


Vue.prototype.$Toast = Toast;